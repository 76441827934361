// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

// init swiper
new Swiper (".swiper-container:not(.-disabled)", {
    autoplay: 3000,
    effect: "fade",
    speed: 150,
    loop: true,
    pagination: ".swiper-pagination",
    paginationClickable: true,
    nextButton: ".swiper-button.-next",
    prevButton: ".swiper-button.-prev",
});
